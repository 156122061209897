import Vue from "vue";
import Router from "vue-router";
import Auth from "@okta/okta-vue";
import store from "@/store.js";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

// Views - Components

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page403 = () => import("@/views/pages/Page403");
const Page500 = () => import("@/views/pages/Page500");

// Account
const AccountDashboard = () => import("@/views/account/AccountDashboard");
const AccountInfo = () => import("@/views/account/AccountInfo");
const AccountAWSSSOManagement = () => import("@/views/account/AccountAWSSSOManagement");
// const AccountLegacyIAMManagement = () => import("@/views/account/AccountLegacyIAMManagement");
const AccountSCPManagement = () => import("@/views/account/AccountSCPManagement");
const AccountRemediationManagement = () => import("@/views/account/AccountRemediationManagement");

// Create Account
const CreateAccountDashboard = () => import("@/views/createaccount/CreateAccountDashboard");
//ChargeBack
const ChargebackDashboard = () => import("@/views/chargeback/ChargebackDashboard");

// Help
const HelpDashboard = () => import("@/views/help/HelpDashboard");

// Auth Callback
const ImplicitCallback = () => import("@/components/ImplicitCallback");

Vue.use(Auth, {
  issuer: process.env.VUE_APP_AUTHPARAMS_ISSUER,
  clientId: process.env.VUE_APP_CLIENTID,
  redirectUri: window.location.origin + "/callback",
  scopes: process.env.VUE_APP_AUTHPARAMS_SCOPES.split(" "),
  pkce: process.env.VUE_APP_AUTHPARAMS_PKCE === "true",
  cookies: {
    secure: false,
    sameSite: "lax",
  },
});

Vue.use(Router);

const router = new Router({
  // mode: 'hash', // https://router.vuejs.org/api/#mode
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior: () => ({
    y: 0,
  }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/dashboard",
      name: "Home",
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "createaccount",
          redirect: "/createaccount/dashboard",
          name: "Create Account",
          beforeEnter(__from, __to, next) {
            if (store.getters.userinfo.User.IsSuperAdmin) {
              console.log("superadmin");
              next();
            } else {
              next({ name: "Home" });
            }
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "dashboard",
              name: "CreateAccount",
              component: CreateAccountDashboard,
            },
          ],
        },
        {
          path: "remediation",
          redirect: "/remediation/dashboard",
          name: "Remediation",
          beforeEnter(__from, __to, next) {
            if (store.getters.userinfo.User.IsSuperAdmin) {
              console.log("superadmin");
              next();
            } else {
              next({ name: "Home" });
            }
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "dashboard",
              name: "RemediationChild",
              component: AccountRemediationManagement,
            },
          ],
        },
        {
          path: "accounts",
          redirect: "/accounts/dashboard",
          name: "Manage Accounts",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "dashboard",
              name: "Accounts",
              component: AccountDashboard,
            },
            {
              path: ":id",
              name: "Account", // Account Info + request Admin Access
              redirect: "/accounts/dashboard",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "info",
                  name: "Account info",
                  component: AccountInfo,
                },
                {
                  path: "awssso",
                  name: "AWS SSO Permission management",
                  component: AccountAWSSSOManagement,
                },
                {
                  path: "scps",
                  name: "SCPs Management",
                  component: AccountSCPManagement,
                },
              ],
            },
          ],
        },
        {
          path: "chargeback",
          redirect: "/chargeback/dashboard",
          name: "Chargeback",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "dashboard",
              name: "Chargeback dashboard",
              component: ChargebackDashboard,
            },
          ],
        },
        {
          path: "help",
          redirect: "/help/dashboard",
          name: "Help",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "dashboard",
              name: "Help dashboard",
              component: HelpDashboard,
            },
          ],
        },
      ],
    },
    {
      path: "/callback",
      components: {
        //default: Auth.handleCallback()
        default: ImplicitCallback,
      },
    },
    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "403",
          name: "Page403",
          component: Page403,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
      ],
    },
  ];
}

router.beforeEach(Vue.prototype.$auth.authRedirectGuard());
export default router;
