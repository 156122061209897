export default {
  regions(state) {
    return state.regions;
  },
  accountWorkInProgress(state) {
    return state.workInProgress["/v1/accounts"];
  },
  chargeBackAccountWorkInProgress(state) {
    return state.workInProgress["/v1/chargeback"];
  },
  ssoModal(state) {
    return state.messages.SsoModal;
  },
  chargebackModal(state) {
    return state.messages.ChargebackModal;
  },
  shouldUpdate(state) {
    const currentTS = state.currentTS;
    const lastLoadingTS = state.lastLoadingTS;
    if (!lastLoadingTS) {
      return true;
    }
    return (currentTS - lastLoadingTS) / 1000 > 3600;
  },
};
